import { defaultClubManagerGroupId } from '@/components/Utility/Base'
import CommonFunctions, { defaultInvoiceURL } from '@/components/Utility/Common'
import AuthService from '@/services/AuthService'
import MembersDataService from '@/services/MembersDataService'
import { Orders } from '@/services/OrdersDataService'
import { Products } from '@/services/ProductsDataService'
import SysMember from '@/types/SysMember'
import SysOrder, { OrderStatusType, SysClubOrders, SysOrderUnitId } from '@/types/SysOrder'
import SysProduct from '@/types/SysProduct'
import { Options, Vue } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { useRoute } from 'vue-router'
import Vue3Html2pdf from 'vue3-html2pdf'
import SysCart from '@/types/SysCart'

type orderDataType = { orderCreated: string; orderUpdated: string; userName: string; orderInvoiceNumber: number; orderComment: string; orderTransaction: number; orderInvoice: any[] | null; orderName: string; orderAddress: string; orderCity: string; orderPostalNumber: string; orderCountry: string; orderTelephone: string; orderEmail: string; orderUnits: SysOrderUnitId[]; orderTotal: number; orderStatus: string; }
type dataReturnType = { clubOrders: any; clubName: string; orderData: orderDataType; orderProducts: any; orderSearchInputValue: string }

@Options({
  components: {
    Vue3Html2pdf
  }
})
export default class orderHistory extends Vue {
  private memberId = this.parseURL()
  private clubOrders: SysClubOrders[] = []
  private tempMember = {} as SysMember
  private orderProducts: SysProduct[] = []
  private clubName = ''
  private orderParam = ''
  private orderData: orderDataType = { orderCreated: '', orderUpdated: '', userName: '', orderInvoiceNumber: 0, orderComment: '', orderTransaction: 0, orderInvoice: [], orderName: '', orderAddress: '', orderCity: '', orderCountry: '', orderTelephone: '', orderEmail: '', orderTotal: 0, orderUnits: [], orderStatus: '', orderPostalNumber: '' }
  private orderInfoModal = false
  private currentOrderSorting = 'ordre_id.ordre_fakturanummer:desc'
  private orgSort = ''
  private isPayFees = ''
  private orderSearchTerm = ''
  // kampNo = 0
  private checkCount = 0
  private orderSearchInputValue = ''
  private currentOrderPage = 1
  private totalOrderPages = 0
  private totalNumberOfOrderPages = 0
  private orderPageSizeValue = 25
  private orderPageSizeValueString = '25'
  private orderPageSizeOptions: string[] = ['10', '25', '100']
  private screenSize = this.findScreenSize()
  todaysDate = new Date().toISOString().split('T')[0]

  readonly name: string = 'orderHistory'
  $Message: any
  data (): dataReturnType {
    return {
      clubOrders: this.clubOrders,
      clubName: this.clubName,
      orderData: this.orderData,
      orderProducts: this.orderProducts,
      orderSearchInputValue: this.orderSearchInputValue
    }
  }

  @Watch('orderPageSizeValueString')
  onPageSizeValueChange (newVal: any) : void {
    this.orderPageSizeValue = Number(newVal)
    this.currentOrderPage = 1
    this.getOrderHistory()
    // CommonFunctions.scrollToTop()
  }

  public findScreenSize () : number {
    console.log(window.outerWidth)
    return window.outerWidth
  }

  public async downloadPdf (index: number) : Promise<void> {
    await this.orderInfo(index, false)
    const pdfApi = this.$refs.html2Pdf as any
    pdfApi.generatePdf()
  }

  public turnToNewOrderPage (pageChange: number) : void {
    this.currentOrderPage += pageChange
    if (this.currentOrderPage < 1) {
      this.currentOrderPage = 1
    } else if (this.currentOrderPage >= this.totalNumberOfOrderPages) {
      this.currentOrderPage = this.totalNumberOfOrderPages
    }
    this.getOrderHistory()
    // CommonFunctions.scrollToTop()
  }

  public jumpToOrderPage (pageNumber: number) : void {
    this.currentOrderPage = pageNumber
    if (this.currentOrderPage < 1) {
      this.currentOrderPage = 1
    } else if (this.currentOrderPage > this.totalNumberOfOrderPages) {
      this.currentOrderPage = this.totalNumberOfOrderPages
    }
    this.getOrderHistory()
    CommonFunctions.scrollToTop()
  }

  public columnSort (sortBy: string) : void {
    if (sortBy === 'reset') {
      this.currentOrderSorting = 'ordre_id.ordre_fakturanummer:desc'
      this.orderSearchInputValue = ''
      this.orderSearchTerm = ''
    }
    if (sortBy === 'invoiceId') {
      if (this.orgSort === '' || this.orgSort !== sortBy) {
        this.currentOrderSorting = 'ordre_id.ordre_fakturanummer:asc'
        this.orgSort = sortBy
      } else {
        this.currentOrderSorting = 'ordre_id.ordre_fakturanummer:desc'
        this.orgSort = ''
      }
    }
    if (sortBy === 'status') {
      if (this.orgSort === '' || this.orgSort !== sortBy) {
        this.currentOrderSorting = 'ordre_id.ordre_status:asc'
        this.orgSort = sortBy
      } else {
        this.currentOrderSorting = 'ordre_id.ordre_status:desc'
        this.orgSort = ''
      }
    }
    if (sortBy === 'total') {
      if (this.orgSort === '' || this.orgSort !== sortBy) {
        this.currentOrderSorting = 'ordre_id.ordre_total:asc'
        this.orgSort = sortBy
      } else {
        this.currentOrderSorting = 'ordre_id.ordre_total:desc'
        this.orgSort = ''
      }
    }
    if (sortBy === 'date') {
      if (this.orgSort === '' || this.orgSort !== sortBy) {
        this.currentOrderSorting = 'created_at:asc'
        this.orgSort = sortBy
      } else {
        this.currentOrderSorting = 'created_at:desc'
        this.orgSort = ''
      }
    }
    this.getOrderHistory()
  }

  public findOrder () : void {
    this.orderSearchTerm = this.orderSearchInputValue
    this.getOrderHistory()
  }

  public async orderInfo (orderId: number, showModal = true) : Promise<void> {
    this.findScreenSize()

    let tempOrder = {} as SysOrder
    await Orders.OrdersDataService.get(orderId.toString())
      .then((response) => {
        tempOrder = response.data

        this.orderData = {
          orderCreated: this.danishDateFormat(tempOrder.created_at),
          orderUpdated: this.danishDateFormat(tempOrder.updated_at),
          userName: (tempOrder.user_id === null ? '' : tempOrder.user_id.username),
          orderInvoiceNumber: tempOrder.ordre_fakturanummer,
          orderComment: tempOrder.ordre_kommentar,
          orderTransaction: tempOrder.ordre_transaktion,
          orderInvoice: (tempOrder.ordre_faktura === null ? null : tempOrder.ordre_faktura),
          orderName: tempOrder.ordre_navn,
          orderAddress: tempOrder.ordre_adresse,
          orderCity: tempOrder.ordre_by,
          orderPostalNumber: tempOrder.ordre_postnummer,
          orderCountry: tempOrder.ordre_land,
          orderTelephone: tempOrder.ordre_telefon,
          orderEmail: tempOrder.ordre_email,
          orderUnits: tempOrder.ordreenheders_id,
          orderStatus: this.parseEnum(tempOrder.ordre_status).toString(),
          orderTotal: tempOrder.ordre_total
        }
        // console.log(this.orderData)

        if (this.orderData.orderUnits.length > 0) {
          let tempProductIds: number[] = []
          let tempProductParameterString = ''

          for (const item of this.orderData.orderUnits) {
            tempProductIds.push(item.produkt_id)
          }

          tempProductIds = tempProductIds.filter(function (elem, index, self) { return index === self.indexOf(elem) })

          for (const id of tempProductIds) {
            tempProductParameterString += '&id=' + id.toString()
          }
          tempProductParameterString = tempProductParameterString.substring(1)

          Products.ProductsDataService.getAll('', null, tempProductParameterString)
            .then((response) => {
              this.orderProducts = response.data
            })
            .catch((err) => {
              console.error(err)
            })
        }
        this.orderInfoModal = showModal
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        console.log('findOrder: ' + orderId)
      })
  }

  public findUnitPrice (unitId = 0) : number {
    let retVal = 0
    const productIndex = this.orderProducts.findIndex(element => element.id === unitId)
    if (productIndex !== -1) {
      retVal = this.orderProducts[productIndex].produkt_pris
    }

    return retVal
  }

  public generateInvoiceURL (url: string) : string {
    return defaultInvoiceURL + url
  }

  public danishDateFormat (date: string) : string {
    return CommonFunctions.toDanishDateString(date)
  }

  public parseURL () : number {
    let retVal = 0
    const route = useRoute()
    const memberID = route.params.userSlug.toString().split('-')[0]
    if (Number(memberID) !== undefined) {
      retVal = Number(memberID)
    }
    return retVal
  }

  public invoiceURL () : string {
    return defaultInvoiceURL
  }

  public parseEnum (typeString: any) : OrderStatusType {
    return <any>OrderStatusType[typeString]
  }

  public retrieveOrderHistory () {
    // Changed to use the userId from loacalStorage
    const userString = localStorage.getItem('user')?.toString()
    const userDataObject = (userString !== undefined && userString !== null ? JSON.parse(userString) : null)

    if (userDataObject === null || userDataObject.id === 1) {
      // console.log('ERROR fetching memberData')
      CommonFunctions.redirectLogin()
      return
    }
    MembersDataService.getAll('', null, `user_id.id=${userDataObject.id}`)
      .then((response) => {
        const members = response.data as SysMember[]
        if (members.length !== 1) {
          this.$Message.danger({ text: 'Fejl: Et eller andet gik galt' })
          AuthService.logout()
          CommonFunctions.redirectLogin()
          return
        }
        if (this.memberId !== Number(members[0].id)) {
          this.$Message.danger({ text: 'Du har ikke rettigheder til at være her' })
          return this.$router.push({ name: 'Login', path: 'medlemsportal/login' })
        }
        this.tempMember = members[0]
        // this.tempMember = response.data

        // Test if tempMember is the "klubansvarlig"
        if (this.tempMember.user_id.usrgroup !== defaultClubManagerGroupId) {
          this.$Message.danger({ text: 'Du har ikke rettigheder til at være her' })
          return this.$router.push({ name: 'Login', path: 'medlemsportal/login' })
        }
        this.clubName = this.tempMember.klubber_id.klubber_klubnavn
        this.orderParam = 'klubber_id.id=' + this.tempMember.klubber_id.id
        this.getOrderHistory()
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public getOrderHistory () : void {
    Orders.ClubOrderDataService.getCount(this.orderParam)
      .then((response) => {
        this.totalOrderPages = response.data
        this.totalNumberOfOrderPages = Math.ceil(this.totalOrderPages / this.orderPageSizeValue)
        if (this.orderSearchTerm.length > 0) {
          Orders.ClubOrderDataService.findBySearchTerm(this.orderSearchTerm, this.currentOrderSorting, { slicemode: 1, start: 0, limit: -1, page: (this.currentOrderPage - 1), pagesize: this.orderPageSizeValue, totalcount: this.totalOrderPages }, this.orderParam)
            .then((response) => {
              this.clubOrders = response.data
            })
            .catch((err) => {
              console.error(err)
            })
        } else {
          Orders.ClubOrderDataService.getAll(this.currentOrderSorting, { slicemode: 1, start: 0, limit: -1, page: (this.currentOrderPage - 1), pagesize: this.orderPageSizeValue, totalcount: this.totalOrderPages }, this.orderParam)
            .then((response) => {
              this.clubOrders = response.data
              // console.log(this.clubOrders)
            })
            .catch((err) => {
              console.error(err)
            })
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public async getProductDetails (status: string) {
    const counter = 1
    await Products.ProductsDataService.getAll('', null, `produkt_status=true&produkt_navn=${status}`)
      .then((response) => {
        const cartItems: SysCart[] = []
        let newItem = {} as SysCart

        newItem = {
          placeInCart: Number(cartItems?.length),
          price: response.data[0]?.produkt_pris,
          productName: response.data[0]?.produkt_navn,
          productDescription: response.data[0]?.produkt_beskrivelse,
          dateInCart: this.todaysDate,
          expirationDate: null,
          playerRegistration: null,
          productId: Number(response.data[0]?.id),
          quantity: counter,
          licenseRenewal: null,
          clubLicenseRenewal: null,
          eventSignUp: null,
          teamRegistration: null,
          competitionFeeData: null,
          orderExtraData: null
        }
        cartItems.push(newItem)
        console.log('cartItems...', cartItems)

        localStorage.setItem('cart', JSON.stringify(cartItems))
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public async onBillButton (kampNummer: number, status: string, orderId: number): Promise<void> {
    await this.getProductDetails(status)
    localStorage.setItem('orderType', JSON.stringify({ orderId, type: 'orderHistory' }))
    this.$router.push({
      path: '/kassen',
      query: {
        isPayFees: 'true'
      }
    })
    this.checkCount = 0
  }

  async mounted () : Promise<void> {
    const loggedInStatus = localStorage.getItem('status')
    const loginType = localStorage.getItem('logintype')
    const apiToken = localStorage.getItem('apitoken')
    const userdata = localStorage.getItem('user')
    if (loggedInStatus !== undefined && loggedInStatus !== null && loggedInStatus === true.toString() && loginType !== undefined && loginType !== null && loginType === true.toString() && apiToken !== undefined && apiToken !== null && apiToken.length >= 100 && userdata !== undefined && userdata !== null && !userdata.startsWith('{"id":1,')) {
      this.retrieveOrderHistory()
    } else {
      CommonFunctions.redirectLogin()
    }
  }
}
